export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";


export const login = ({ firstName, lastName, email, phone, sub, token }) => {
  return dispatch => {
    dispatch({
      type: LOGIN,
      payload: { firstName, lastName, email, phone, sub, token }
    });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
  };
};
