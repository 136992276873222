import React, {useEffect, useRef} from "react";
import TextPath from "./paths/TextPath";
import MediaPath from "./paths/MediaPath";
import ImagePath from "./paths/ImagePath";
import AudioPath from "./paths/AudioPath";
import VideoPath from "./paths/VideoPath";
import FilePath from "./paths/FilePath";
import QuickReplyPath from "./paths/QuickReplyPath";


const PathMessage = ({message, me, sendMessage}) => {
  const key = Object.keys(message)[0];

  switch (key) {
    case 'text': return <TextPath data={message} me={me} sendMessage={sendMessage}/>
    case 'media': return <MediaPath data={message} me={me} sendMessage={sendMessage} />
    case 'image': return <ImagePath data={message} me={me} sendMessage={sendMessage} />
    case 'audio': return <AudioPath data={message} me={me} sendMessage={sendMessage} />
    case 'video': return <VideoPath data={message} me={me} sendMessage={sendMessage} />
    case 'file': return <FilePath data={message} me={me} sendMessage={sendMessage} />
    case 'quick_reply': return <QuickReplyPath data={message} me={me} sendMessage={sendMessage} />
  }
  return null;
}


const ChatBody = ({messages, sendMessage}) => {
  const chatDiv = useRef(null);

  useEffect(() => {
    if (chatDiv.current) chatDiv.current.scroll({top: chatDiv.current.scrollHeight, behavior: 'smooth'})
  }, [messages])

  return (
    <div
      ref={chatDiv}
      className="chat-body" id="body"
      style={{padding: '8px 12px', display: 'flex', flexDirection: 'column', zIndex: 0}}>
      {
        messages.map(({payload, me}, index) => {
          return <PathMessage message={payload} me={me} key={index} sendMessage={sendMessage}/>
        })
      }
      <div className="extra-margin" style={{width: '100%', background: 'transparent', height: '20px', flexShrink: 0}}/>
    </div>
  )
}

export default ChatBody;
