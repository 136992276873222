export const SHOW_CHAT = "SHOW_CHAT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const CHANGE_CHAT = "CHANGE_CHAT";


export const addMessage = ({ me, payload }) => {
  return dispatch => {
    dispatch({
      type: ADD_MESSAGE,
      payload: { me, payload }
    });
  };
};
export const showChat = (value) => {
  return dispatch => {
    dispatch({
      type: SHOW_CHAT,
      payload: value
    });
  };
};
export const clearChat = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_CHAT
    });
  };
};
export const changeChat = (value) => {
  return dispatch => {
    dispatch({
      type: CHANGE_CHAT,
      payload: value
    });
  };
};
