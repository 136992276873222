import React, {useState} from "react";

const ChatInput = ({onSend}) => {
  const [value, setValue] = useState('');

  return (
    <div className="footer">
      <input placeholder="Type a message" dir="ltr" id="chat-input" autoComplete="off" type="text" value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === 'NumpadEnter') {
          e.preventDefault();

          onSend(value);
          setValue('');
        }
      }}/>
      <button type="submit" className="icon" id="chat-input-icon" style={{border: 'none'}} onClick={() => {
        onSend(value);
        setValue('');
      }}>
      <span className="fa fa-send"/></button>
    </div>
  )
}

export default ChatInput;
