import React from "react";


const ImageItem = ({data, sendMessage}) => {
  return (
    <div className="item" dir="ltr">
      <div className="bot-carousal-item shadow-theme">
        <div className="banner"
             style={{backgroundImage: `url("${data.url}")`}}/>
        <div style={{listStyle: 'none'}}>
          <li className="title-wrapper">
            <div className="title-text">
              <div style={{}} className="inner-html-wrapper">{data.title}</div>
            </div>
            <div className="description-text">
              <div style={{}} className="inner-html-wrapper"><p>{data.description}</p>
              </div>
            </div>
          </li>
          {
            data.buttons.length > 0 && data.buttons.map(button => {
              switch (button.type) {
                case 'postback': {
                  return (
                    <li className="action" onClick={() => sendMessage({payload: button.payload, display: button.title})}>
                      <div className="link-wrapper svelte-yk721s"><span>{button.title}</span></div>
                    </li>
                  );
                }
                case 'url': {
                  return (
                    <li className="action" onClick={() => window.open(button.url)}>
                      <div className="link-wrapper svelte-yk721s"><span className="link-inner"><i
                        className="fa fa-external-link"
                        style={{marginRight: '5px', lineHeight: '8px'}}/> <span
                        className="link-inner-link svelte-yk721s">{button.title}</span></span></div>
                    </li>
                  );
                }
              }
            })
          }
        </div>
      </div>
    </div>
  )
}

const MediaPath = ({data, me, sendMessage}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div className="carousal-container  hide-left-control" data-step={0} style={{overflow: 'visible'}}>
          <div style={{overflow: 'auto'}}>
            <div style={{overflow: 'hidden', display: 'block', justifyContent: 'center'}}>
              <div className="carousal-container-inner">
                {
                  data.media.length > 0 && data.media.map(media => {
                    switch (media.type) {
                      case 'image': {
                        return <ImageItem data={media} sendMessage={sendMessage}/>
                      }
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default MediaPath;
