import {
  LOGIN,
  LOGOUT
} from "../actions/authActions";

const initState = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  sub: null,
  token: null,
};

const authReducer = (state = initState, action) => {
  const payload = action.payload;

  if (action.type === LOGIN) {
    return {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phone,
      sub: payload.sub,
      token: payload.token,
    }
  }
  if (action.type === LOGOUT) {
    // for non variant products
    return initState
  }

  return state;
};

export default authReducer;
