import React from "react";

const VideoPath = ({data, me}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div className="message-wrapper  message-wrapper-bot msg-video"
             style={{animationFillMode: 'none !important'}}>
          <video className="msg-video" controls playsInline>
            <source src={data.video.url}/>
            Your browser does not support HTML5 video.
          </video>
        </div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default VideoPath;
