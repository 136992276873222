import React from "react";

const QuickReplyPath = ({data, me, sendMessage}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div dir="ltr" className="message-wrapper ">
          <div className="content">
            <div className="inner-html-wrapper">
              <p>
                {data.quick_reply.text.toString().replaceAll("<br>", "\n")}
              </p>
            </div>
          </div>
        </div>
        <div className="message-wrapper-quick-reply">
        {
          data.quick_reply.quick_replies.length > 0 && data.quick_reply.quick_replies.map((reply, key) => {
            switch (reply.content_type) {
              case 'text': {
                return (
                  <div key={key} className="message-wrapper-quick-reply-button link-wrapper bot-link bot-link-0" dir="ltr"
                       onClick={() => sendMessage({payload: reply.payload, display: reply.title})} style={{position: 'relative'}}>
                    <div className="link-wrapper svelte-yk721s"><span>{reply.title}</span></div>
                  </div>
                )
              }
              case 'url': {
                return (
                  <div onClick={() => window.open(reply.url)} className="message-wrapper-quick-reply-button link-wrapper bot-link bot-link-2" dir="ltr" style={{position: 'relative'}}>
                    <div className="link-wrapper svelte-yk721s"><span className="link-inner">
                      <i className="fa fa-external-link" style={{marginRight: '5px', lineHeight: '8px'}}/>
                      <span className="link-inner-link svelte-yk721s">{reply.title}</span></span></div>
                  </div>
                )
              }
            }
          })
        }
        </div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default QuickReplyPath;
