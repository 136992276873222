import React, {useEffect, useState} from "react";

import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import ChatInput from "./ChatInput";
import ChatIcon from "./ChatIcon";
import {useIMIChat} from "../../hooks/useIMIChat";
import {connect} from "react-redux";
import {addMessage, changeChat, clearChat, showChat} from "../../redux/actions/chatActions";
import * as moment from "moment";
import {addToCart} from "../../redux/actions/cartActions";


const ChatGadget = ({authData, chatData, addMessage, showChat, clearChat, changeChat, addToCart, products}) => {
  const [chatEnded, setChatEnded] = useState(false);
  const {
    sendMessage,
    refresh,
    init,
  } = useIMIChat({
    addMessage,
    clearChat,
    chatData,
    authData,
    showChat,
    addToCart,
    products,
    changeChat
  });

  return (
    <>
      <div className={`dewa-theme ${chatData.chatVisible ? 'show-chat' : ''}`}>
        <div className={`test-container ltr`}>
          <div className={`imi-preview-grid-container`}>
            <ChatHeader
              title={chatData.chatTitle}
              onRefresh={async () => {
                await refresh();
              }}
            />
            {
              !chatEnded ? (
                <>
                  <ChatBody messages={chatData.messages} sendMessage={sendMessage}/>
                  <ChatInput onSend={(payload) => sendMessage({payload})}/>
                </>
              ) : (
                <>
                </>
              )
            }

          </div>
        </div>
      </div>

      <ChatIcon
        visible={chatData.chatVisible}
        onClick={async (visible) => {
          await init(
            async () => {
              await sendMessage({
                payload: 'START',
                show: false,
              });
            }
          );
          showChat(visible);

        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.authData,
    chatData: state.chatData,
    products: state.productData.products
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addMessage: (payload) => {
      dispatch(addMessage(payload));
    },
    showChat: (payload) => {
      dispatch(showChat(payload));
    },
    clearChat: () => {
      dispatch(clearChat());
    },
    changeChat: (type) => {
      dispatch(changeChat(type));
    },
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGadget);

