import React from "react";

const ImagePath = ({data, me}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div className="message-wrapper  message-wrapper-bot msg-video"
             style={{animationFillMode: 'none !important'}}>
          <img title="click to enlarge" className="msg-img click-to-zoom svelte-1g31fei"
               src={data.image.url} alt=""
               style={{maxWidth: '357px', width: '100%', borderRadius: '8px', overflow: 'hidden'}}/></div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default ImagePath;
