import React from "react";


const ChatIcon = ({visible, onClick}) => {
  return (
    <button className="chat-button show" onClick={() => onClick(!visible)}>
      <i className="fa fa-comment"/>
    </button>
  )
}

export default ChatIcon;
