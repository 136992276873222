import React from "react";


const ChatHeader = ({title, onRefresh}) => {
  return (
    <div className="header">
      <div className="bot-intro" id="botIntro"><span className="bot-logo">
        <img id="bot-logo"
             src="/assets/img/chat/search-bot-icon.svg"
             alt=""/></span>
        <div className="bot-details svelte-17rmok8">
          <div id="bot-title" className="svelte-17rmok8">{title}</div>
        </div>
        <div className="options" onClick={onRefresh}><i title="Restart a new chat" className="fa fa-refresh refresh-button"/></div>
      </div>
    </div>
)
}

export default ChatHeader;
