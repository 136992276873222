import React from "react";

const FilePath = ({data, me}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div className="message-wrapper ">
          <div className="message-wrapper-file svelte-amvzu8">
            <img src="/assets/img/chat/pdf.svg" alt="" className="svelte-amvzu8"/>
            <div dir="ltr" className="title overlflow-test svelte-amvzu8" style={{flexGrow: 1}} title="sample.pdf">
              <div style={{overflow: 'hidden', whiteSpace: 'nowrap', width: '163px', textOverflow: 'ellipsis'}}
                   className="inner-html-wrapper">sample.pdf
              </div>
            </div>
            <a className="download svelte-amvzu8" target="_blank" download
               href={data.file.url}><img src="/assets/img/chat/download.svg"
                                                                            alt="" className="svelte-amvzu8"/></a>
          </div>
        </div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default FilePath;
